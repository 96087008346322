<template>
  <section class="hero is-fullheight">
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Transacciones
                  </h1>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <input
                    class="input"
                    type="text"
                    placeholder="Buscador"
                    v-model="search"
                    @change="searchAction"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12 is_grid pb-0">
            <div class="table-container">
              <BaseTable
                :columns="columnsData"
                :data="transationsList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p
                    class="text_status has-text-weight-medium"
                    :class="[ scope.row.status === IS_PENDING ? 'has_warning' : (scope.row.status === IS_APPROVED ? 'has_success' : 'has_danger') ]"
                  >
                    {{ scope.row.status_name }}
                  </p>
                </div>
              </BaseTable>
            </div>
          </div>
          <div class="column is-12 pt-0">
            <div class="pagination_container">
              <BasePagination
                :page="page"
                :pages="parseInt(pages)"
                :action="paginator"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TransactionsMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination')
  },
  data () {
    return {
      IS_PENDING: 1,
      IS_APPROVED: 2,
      IS_REJECTED: 3,
      loading: false,
      page: 1,
      pages: 1,
      search: '',
      limit: 10,
      columnsData: [
        { id: 'id', header: 'ID' },
        {
          id: 'product_name',
          header: 'Producto Servicio',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-transaccion', params: { transactionId: row.id } })
        },
        { id: 'interestedUser', header: 'Usuario interesado', accessor: (row) => `${row.name} ${row.last_name || ''} ${row.second_last_name || ''}` },
        { id: 'amount', header: 'Cantidad solicitada', class: 'has-text-centered' },
        { id: 'status', name: 'status', header: 'Estatus', class: 'has-text-centered' }
      ],
      transationsList: []
    }
  },
  methods: {
    ...mapActions(['getTransactionsList']),
    async getList (page, limit, search) {
      this.loading = true
      const resp = await this.getTransactionsList({ page, limit, search })
      if (resp.success) {
        this.transationsList = resp.payload
        this.pages = resp.totalPages
      }
      this.loading = false
    },
    searchAction () {
      this.page = 1
      this.getList(this.page, this.limit, this.search)
    },
    paginator (page) {
      this.page = page
      this.getList(this.page, this.limit, this.search)
    }
  },
  beforeMount () {
    this.getList(this.page, this.limit, this.search)
  }
}
</script>

<style lang="scss" scoped>
  .input {
    border-radius: 3px;
    background-color: #fff;
    border: none;
    min-width: 300px;
  }
  .btn_aqua {
    height: 3em;
  }
  .text_status {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.13px;
  }
</style>
